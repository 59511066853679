

/* Base */
.content-block{
	padding-bottom: 0;

  img,picture{
    width: 100%;
    display: block;
  }
}

/* Text content */
.content{
	li{
		margin-bottom: 0.5em;
	}

  &.is-inverted{
    p,ul,li,h3,h4,h5,h6{
      color: $white;
    }
    strong{
      color: $white !important;
    }
    a{
      color: $white;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}


/* Component | Content block styles */

@import "_matrix/text-block.scss";
@import "_matrix/text-heading-block.scss";
@import "_matrix/text-image-block.scss";
@import "_matrix/text-columns-block.scss";
@import "_matrix/image-block.scss";
@import "_matrix/awards-block.scss";
@import "_matrix/video-block.scss";
@import "_matrix/button-block.scss";
@import "_matrix/heading-block.scss";
@import "_matrix/faq-block.scss";
@import "_matrix/reviews-block.scss";
@import "_matrix/brand-block.scss";
@import "_matrix/collapsable-block.scss";
