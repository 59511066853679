

  .announcement{
		position: relative;
		z-index: 20;
    background-color: $red;
    color: $white;

		@include touch {
			font-size: 0.7em;
			padding: 1.5em 0 !important;
		}
		@include desktop {
			font-size: 1.1em;
			padding: 1.25em 0;
		}

		a{
			color: $white;
			&:hover{
				text-decoration: underline;
			}
		}
    ul{
      text-align: center;
      display: block;
    }
    li{
      display: inline-block;
      margin: 0;
      padding:0 0.8em 0 0.5em;
      border-right: 3px solid darken($red,10);

      &:last-child{
        border: none;
      }
    }
  }
