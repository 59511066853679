
.text-heading-block{

  // Heading column
  .aside{

    blockquote{
      margin: 0;
    }
    h3,strong,p{
      color: $red;
    }
  }
  h3{
    line-height: 1.35em;
    padding-right: 3em;
  }


}
