
.admin-edit{
  z-index: 850;
  background-color: $white;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0.5em;

  @include box_shadow(2, 'top');

  .buttons{
    padding: 0.25em;
  }

  .qrcode{
    color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    img{
    //  @include shadow(2);
      width: 150px;
    }
  }
}
