

ul{
  &.decorated-list{

    @include mobile {
      font-size: 1em;
    }
    @include tablet {
      font-size: 1.2em;
    }

    li{
      position: relative;
      &:before{
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        background-image: url('../img/icon-chevron.svg');
        background-size: contain;
        position: absolute;
        top: 5px;
        left: -30px;
      }
    }
  }

  &.two-up{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  &.three-up{

    @include mobile {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
      column-gap: 0;
    }
    @include tablet {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      column-gap: 40px;
    }
    @include desktop {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
      column-gap: 80px;
    }
  }

}



ol.nested-counter-list {
  counter-reset: item;
  margin: 0;
  padding: 0;

  li {
    display: block;

    &::before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      font-weight: bold;
    }
  }

  ol {
    counter-reset: item;

    & > li {
      display: block;

      &::before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: bold;
      }
    }

    ol.text{
      counter-reset: letter;
      & > li {
        &::before {

          content: counter(letter, lower-alpha) ") ";
          counter-increment: letter;
        }
      }
    }
    ol.roman{
      counter-reset: roman;
      & > li {
        &::before {

          content: counter(roman, lower-roman) ") ";
          counter-increment: roman;
        }
      }
    }
  }
}
