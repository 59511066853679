
.footer{
	@include panelHover;

  color: $white;
  position: relative;
  background-color: $blue-dark;
  @include gradient(180deg, $grad1);

  @include touch {
    padding: 3em 0;
    font-size: 0.8em;
  }
  @include desktop {
    padding: 5em 2em;
    font-size: 0.9em;
  }

  a{
    color: $white;
    &:hover{
      text-decoration: underline;
    }
  }

	i{
		margin-right: 10px;
		color: $red;
	}

  .label{
    color: $white;
    font-weight: normal;
  }
}
