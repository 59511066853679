
.page-leader{
	position: relative;

  .image{
    @include touch {
      order: 1;
    }
    @include desktop {
      order: 2;
    }
  }
  .summary{
    @include touch {
      order: 2;
    }
    @include desktop {
      order: 1;
    }
  }

	// &:after{
	//
	// 	position: absolute;
	// 	content: '';
	// 	display: block;
	// 	width: 100%;
	// 	height: 2px;
	// 	bottom: 0;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// 	background: #e4e6f2;
	// }
}
