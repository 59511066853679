
.cta-button{

	text-align: center;
	display: inline-block;
	background-color: $red;
	color: $white;
	font-weight: lighter !important;
	text-decoration: none !important;

	@include mobile {
		font-size: 1.35em;
		padding: 1em 2.25em;
		width: 100%;
	}
	@include tablet {
		font-size: 1.7em;
		padding: 1em 2.25em;
		width: 100%;
	}
	@include desktop {
		font-size: 2em;
		padding: 1em 3em;
		width: auto;
	}

	&:hover{
		color: $white;
		background-color: $red-dark;
	}
}
