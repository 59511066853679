
// Custom variables
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap');
$titleFont:     "Poppins", sans-serif;
$bodyFont:      "Poppins", sans-serif;


// Custom Colours
$white:          #ffffff;
$black:          #000000;
$grey-dark:      #2E2E2E;
$grey-mid:       #6A6A6A;
$grey-light:     #e5e5e5;
$grey-xlight:    #F7F8F6;
$red:            #CC372F;
$red-dark:       #af1b13;
$blue:           #2F50A2;
$blue-dark:      #0F1D74;
$blue-light:     #e4e6f2;

// Custom variables
$global-gutter:  2em;


// Bulma overides
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++

// General
$body-size:                  18px;
$primary:                    $blue;
$text:                       $black;
$link:                       $red;
$link-hover:                 $red-dark;


// Font family
$family-sans-serif:          $titleFont;

// Heading sizes
$size-1:                     3.1rem;
$size-2:                     2.8rem;
$size-3:                     2.1rem;
$size-4:                     1.5rem;
$size-5:                     1.2rem;
$size-6:                     1rem;
$size-7:                     0.9rem;

// Titles
$title-family:               $titleFont;
$title-color:                $blue-dark;
$title-weight:               400;
$subtitle-family:            $titleFont;
$subtitle-color:             $blue;
$subtitle-weight:            100;



$section-padding:            3rem 1.5rem;
$section-padding-medium:     6rem 4.5rem;
$section-padding-large:      12rem 6rem;


//
// $content-blockquote-background-color:   none;
// $content-blockquote-border-left:        none;
// $content-blockquote-padding:            0 1.5em;
//
// $modal-z:                              1000;
// $modal-background-background-color:    rgba($black,0.85);
//
//
//
// $gap: 32px !default;
// // 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
// $tablet: 769px !default;
// // 960px container + 4rem
// $desktop: 1152px + (2 * $gap) !default;
// // 1152px container + 4rem
// $widescreen: 1344px + (2 * $gap) !default;
// $widescreen-enabled: true !default;
// // 1344px container + 4rem
// $fullhd: 1736px + (2 * $gap) !default;
// $fullhd-enabled: true !default;
