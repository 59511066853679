
button,
.button{

  border: none;
  border-color: none;
  border-radius: 0;

  &:focus{
    box-shadow: none !important;
  }

  &.is-inverted{
    color: $white;
    &:hover{
      color: $white;
    }
  }
}



.button-red{
	display: inline-block;
	margin: 0.5em 0;
	padding: 0.75em 1em;
	color: $white;
	background-color: $red;
	&:hover{
		color: $white;
		background-color: $red-dark;
		text-decoration: underline;
	}
}



//  Product brochure download button
// ++++++++++++++++++++++++++++++++++
.brochure{
	a{
		text-decoration: none !important;
	}
}
.button-download{
	display: inline-block;
	margin: 0.5em 0;
	padding: 0.75em 1em;
	color: $white;
	background-color: $red;
	&:hover{
		color: $white;
		background-color: $red-dark;
		text-decoration: underline;
	}
}


// Call us - Animated button
// ++++++++++++++++++++++++++++++++++

.phone{
  @include touch {
    transform: scale(0.95) translateX(-7%);
    transform-origin: center right;
  }
  @include desktop {
    transform: scale(1.25) translateX(-10%);
    transform-origin: center right;
  }

  .button{
    background-color: $red;
    &:hover{
      background-color: $red-dark;
    }
  }

  .btn-info{
    &:hover{
      &:before{
        background-color: $red-dark;
      }
    }
    &:before{
      background-color: $red;
      background-image: url('../img/btn-anim-call.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      position: absolute;
      width: 100%;
      height:100%;
      top: 0;
      left:0;
      animation-name: btn-anim;
      animation-duration: 10s;
      animation-delay: 2s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      opacity: 0;
    }
  }

  @keyframes btn-anim {
    0%   {opacity:0}
    10% {opacity:1}
    50% {opacity:1}
    60% {opacity:0}
    100% {opacity:0}
  }

}
