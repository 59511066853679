.title{
  &.is-inverted{
    background-color: $blue-dark;
    @include gradient(180deg, $grad1);
    padding: 1.5em;
    color: $white;
  }
}

.subtitle{
  font-weight: 100 !important;
}



.content{
	h3,h4{
	//	background-color: red;
	}
}


h2,h3,h4,h5,h6{
	a{
		color: $black;
		&:hover{
			color: $red;
		}
	}
}
