
// Swiper container
.service-hero-swiper{


	.flickity-viewport{
		cursor: pointer !important;
	}

	// Nav
	.flickity-button{
			border-radius: 0;
			background-color: $white;
			//top: auto;


			@include mobile {
				width: 4em;
				height: 4em;
				top: 55%;
			}
			@include tablet {
				width: 6em;
				height: 6em;
				top: 50%;
			}

			&.next{
				right: 0;
				left: auto;
			}
			&.previous{
				left: 0;
				right: auto;
			}
	}


	// Slide
	.slide {
		position: relative;

		background: $black;
		background-size: cover;

		@include mobile {
			width: 100%;
			height: 520px;
		}
		@include tablet {
			width: 60%;
			height: 540px;
		}
		@include desktop {
			width: 40%;
			height: 610px;
		}

		&:after{
			@include animate;
			position: absolute;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: $black;
			opacity: 0.2;
		}
	}

	.slide-title{
		@include animate;
		font-weight: bold;
		position: absolute;
		width: 50%;
		left: 10%;
		line-height: 1em;
		color: $red;
		opacity: 0;
		z-index: 1;

		@include mobile {
			font-size: 2em;
			top: 20%;
    }
		@include tablet {
			font-size: 2.4em;
			top: 25%;
    }
    @include desktop {
			font-size: 2.2em;
			top: 30%;
    }

	}
	.slide-summary{
		@include animate;
		position: absolute;
		width: 80%;
		bottom: 10%;
		left: 10%;
		color: $white;
		opacity: 0;
		z-index: 2;

		@include mobile {
			font-size: 0.8em;
    }
		@include tablet {
			font-size: 0.9em;
    }
    @include desktop {
			font-size: 1em;
    }
	}

	.slide-icon{
		@include animate;
		font-size: 4.3em;
		position: absolute;
		right:10%;
		color: $white;
		opacity: 0;
		z-index: 3;

		@include mobile {
			top: 15%;
		}
		@include tablet {
			top: 22%;
		}
		@include desktop {
			top: 28%;
		}
	}


	// ===================================
	.is-selected{
		&:after{
			@include animate;
			position: absolute;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			opacity: 0.7;
		}

		.slide-title{
			@include animate;
			font-weight: bold;
			position: absolute;
			line-height: 1em;
			color: $red;
			opacity: 1;

		}
		.slide-summary{
			@include animate;
			position: absolute;
			width: 80%;
			bottom: 10%;
			left: 10%;
			color: $white;
			opacity: 1;
		}
		.slide-icon{
			@include animate;
			position: absolute;
			right:10%;
			color: $red;
			opacity: 1;
		}
	}

}



// .service-hero-panel{
//
// 	.service-hero-swiper {
// 		overflow: visible !important;
// 		position: relative;
// 		background-color: $red;
// 	  width: 100%;
// 		height: 610px;
// 		z-index: 1;
//
// 		img {
// 		  display: block;
// 		  width: 100%;
// 		  height: 100%;
// 		  object-fit: cover;
// 		}
//
// 		a{
// 			display: block;
// 			width: auto;
// 			height: 50%;
// 		}
// 	}
//
// 	.swiper-slide {
// 		background-image: url('../img/service-hero-panel-bg.jpg');
// 		background-size: cover;
// 	  text-align: left;
// 	  font-size: 18px;
// 	  display: flex;
// 	  justify-content: center;
// 	  align-items: center;
// 		color: $white;
//
// 		a{
// 			&:hover{
// 				.slide-title{
// 					//color: $blue;
// 					&:after{
// 						@include animate;
// 						width: 100%;
// 						background: $red;
// 					}
// 				}
// 				.slide-icon{
// 					@include animate;
// 					color: $red;
// 				}
// 			}
// 		}
//
// 		.slide-title{
// 			@include animate;
// 			font-weight: bold;
// 			position: absolute;
// 			top: 40%;
// 			left: 15%;
// 			font-size: 2.3em;
// 			line-height: 1em;
// 			width: 50%;
// 			color: $red;
//
// 			@include touch {
// 				font-size: 1.6em;
// 			}
// 			@include desktop {
// 				font-size: 1.6em;
// 			}
// 			@include widescreen {
// 				font-size: 2em;
// 			}
//
// 			&:after{
// 				@include animate;
// 				position: absolute;
// 				content: '';
// 				display: block;
// 				width: 0%;
// 				height: 10px;
// 				bottom: -30px;
// 				left: 0;
// 				background: $white;
// 			}
// 		}
//
// 		.slide-summary{
// 			@include animate;
// 			position: absolute;
// 			bottom: 0;
// 			left: 5%;
// 			width: 80%;
//
// 			opacity: 0;
// 			@include touch {
// 				font-size: 0.9em;
// 			}
// 			@include desktop {
// 				font-size: 1.1em;
// 			}
// 		}
// 		.slide-icon{
// 			@include animate;
// 			font-size: 5em;
// 			position: absolute;
// 			top: 15%;
// 			right:10%;
// 			transform: scale(0);
// 		}
// 		// Bg
// 		&:before{
// 			@include animate;
// 			position: absolute;
// 			z-index: -2;
// 			content: '';
// 			display: block;
// 			width: 100%;
// 			height: 100%;
// 			top: 0;
// 			left: 0;
// 			@include gradient(0deg, $grad3);
// 			opacity: 0.9;
//
// 		}
// 		// Border
// 		&:after{
// 			@include animate;
// 			position: absolute;
// 			z-index: -1;
// 			content: '';
// 			display: block;
// 			width: 100%;
// 			height: 100%;
// 			top: -60px;
// 			left: -30px;
// 			//background: yellow;
// 			border:0px solid $red;
// 		}
//
// 	}
//
//
//
//
//
// 	.swiper-slide-active{
// 		position: relative;
// 		z-index: 5;
//
// 		.slide-title{
// 			@include animate;
// 			position: absolute;
// 			top: 20%;
// 			left: 5%;
// 			//width: 50%;
// 			color: $white;
//
// 			@include touch {
// 				font-size: 1.8em;
// 			}
// 			@include desktop {
// 				font-size: 1.8em;
// 			}
// 			@include widescreen {
// 				font-size: 2.2em;
// 			}
// 		}
//
// 		.slide-summary{
// 			@include animate;
// 			position: absolute;
// 			left: 5%;
// 			bottom: 15%;
// 			width: 90%;
// 			opacity: 1;
// 		}
// 		.slide-icon{
// 			@include animate;
// 			color: $white;
// 			@include touch {
// 				transform: scale(0.85);
// 			}
// 			@include desktop {
// 				transform: scale(0.9);
// 			}
// 			@include widescreen {
// 				transform: scale(1);
// 			}
// 		}
// 		// Bg
// 		&:before{
// 			@include animate;
// 			@include gradient(0deg, $grad3);
// 			opacity: 0.6;
// 		}
// 		// Border
// 		&:after{
// 			@include animate;
// 			position: absolute;
// 			z-index: -1;
// 			content: '';
// 			display: block;
// 			width: 100%;
// 			height: 100%;
// 			top: -31px;
// 			left: -30px;
// 			//background: yellow;
// 			border:30px solid $red;
// 		}
// 	}
//
//
// 	// Nav
// 	.swiper-button-prev,
// 	.swiper-button-next{
// 		background-color: $red;
// 		padding: 2em;
// 		color: $white;
//
// 		&:hover{
// 			background-color: $red-dark;
// 		}
// 	}
// 	.swiper-button-prev{
//
// 		@include mobile {
// 			left: 0;
// 			right: auto;
// 		}
// 		@include tablet {
// 			top: auto;
// 			bottom: 0;
// 			left: 0;
// 			right: auto;
// 		}
// 		@include desktop {
// 			left: auto;
// 			right: 75px;
// 			top: auto;
// 			bottom: 0;
// 		}
// 	}
// 	.swiper-button-next{
// 		@include mobile {
// 			left: auto;
// 			right: 0;
// 		}
// 		@include tablet {
// 			top: auto;
// 			bottom: 0;
// 			left: auto;
// 			right: 0;
// 		}
// 		@include desktop {
// 			left: auto;
// 			right: 0;
// 			top: auto;
// 			bottom: 0;
// 		}
// 	}
//
//
//
//
// }
