// Gradient – @include gradient(90deg, $grad1);
$grad1: $blue-dark, $blue;
$grad2: $red, $red-dark;
$grad3: $black, $grey-dark;
$grad4: $grey-light, $white;
// $grad-eg: $blue 0%,$blue 33%,$green 33%,$green 66%, $orange 66%, $orange 100%;

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}


@mixin box_shadow ($level, $side) {

  @if $side == 'bottom' {
    @if $level == 1 {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.0), 0 1px 3px rgba(0, 0, 0, 0.0);
    } @else if $level == 2 {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.12);
    } @else if $level == 3 {
        box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 5px 10px rgba(0,0,0,0.14);
    } @else if $level == 4 {
        box-shadow: 0 14px 28px rgba(0,0,0,0.05), 0 6px 17px rgba(0,0,0,0.2);
    } @else if $level == 5 {
        box-shadow: 0 25px 45px rgba(0,0,0,0.10), 0 15px 22px rgba(0,0,0,0.14);
    }
  }

  @if $side == 'left' {
    @if $level == 1 {
        box-shadow: 2px 0  3px rgba(0, 0, 0, 0.12), 1px 0 3px rgba(0, 0, 0, 0.08);
    } @else if $level == 2 {
        box-shadow: 3px 0 6px rgba(0,0,0,0.16), 2px 0 4px rgba(0,0,0,0.12);
    } @else if $level == 3 {
        box-shadow: 10px 0  20px rgba(0,0,0,0.19), 5px 0 10px rgba(0,0,0,0.14);
    } @else if $level == 4 {
        box-shadow: 14px 0  28px rgba(0,0,0,0.25), 10px 0 17px rgba(0,0,0,0.2);
    } @else if $level == 5 {
        box-shadow: 19px 0  38px rgba(0,0,0,0.30), 15px 0 22px rgba(0,0,0,0.24);
    }
  }

  @if $side == 'top' {
    @if $level == 1 {
        box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.12), 0 -1px 3px rgba(0, 0, 0, 0.08);
    } @else if $level == 2 {
        box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -2px 4px rgba(0,0,0,0.12);
    } @else if $level == 3 {
        box-shadow: 0 -10px 20px rgba(0,0,0,0.19), 0 -5px 10px rgba(0,0,0,0.14);
    } @else if $level == 4 {
        box-shadow: 0 -14px 28px rgba(0,0,0,0.25), 0 -10px 17px rgba(0,0,0,0.2);
    } @else if $level == 5 {
        box-shadow: 0 -19px 38px rgba(0,0,0,0.30), 0 -15px 22px rgba(0,0,0,0.24);
    }
  }

  @if $side == 'right' {
    @if $level == 1 {
        box-shadow: -2px 0  3px rgba(0, 0, 0, 0.12), -1px 0 3px rgba(0, 0, 0, 0.08);
    } @else if $level == 2 {
        box-shadow: -3px 0 6px rgba(0,0,0,0.16), -2px 0 4px rgba(0,0,0,0.12);
    } @else if $level == 3 {
        box-shadow: -10px 0  20px rgba(0,0,0,0.19), -5px 0 10px rgba(0,0,0,0.14);
    } @else if $level == 4 {
        box-shadow: -14px 0  28px rgba(0,0,0,0.25), -10px 0 17px rgba(0,0,0,0.2);
    } @else if $level == 5 {
        box-shadow: -19px 0  38px rgba(0,0,0,0.30), -15px 0 22px rgba(0,0,0,0.24);
    }
  }

  @if $side == 'middle' {
    @if $level == 1 {
        box-shadow: 0 0  3px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.08);
    } @else if $level == 2 {
        box-shadow: 0 0 6px rgba(0,0,0,0.16), 0 0 4px rgba(0,0,0,0.12);
    } @else if $level == 3 {
        box-shadow: 0 0  20px rgba(0,0,0,0.19), 0 0 10px rgba(0,0,0,0.14);
    } @else if $level == 4 {
        box-shadow: 0 0  28px rgba(0,0,0,0.25), 0 0 17px rgba(0,0,0,0.2);
    } @else if $level == 5 {
        box-shadow: 0 0  38px rgba(0,0,0,0.30), 0 0 22px rgba(0,0,0,0.24);
    }
  }

}



@mixin animate($dur:0.3s,$delay:0s) {
  -webkit-transition: all $dur ease-in-out $delay;
  -moz-transition: all $dur ease-in-out $delay;
  -o-transition: all $dur ease-in-out $delay;
  -ms-transition: all $dur ease-in-out $delay;
  transition: all $dur ease-in-out $delay;
}





// generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
   @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}







@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
  }
}




@mixin panelHover(){
	&:before{
		z-index: 2;
		@include animate;
		position: absolute;
		top: -15px;
		left: 0;
		width: 100%;
		height: 15px;
		background-color: $red;
		content: ''
	}

	&:hover{
		&:before{
			@include animate;
			top: -15px;
		}
	}
}
