

.jobs-form-panel{

  .summary-table{
    padding: 2em;
    //background-color: $grey-light;
    @include box_shadow(3,'bottom');
  }

  .job-form{
    @include box_shadow(3,'bottom');
    padding: 2em;
    border-top: 12px solid $red;
  }

  .fui-input{
    border-radius: 0;
  }
  .fui-submit{
    background: $red;
    border: none;
    border-radius: 0;
    &:hover{
      background: $red-dark;
    }
  }
  .fui-label{
    font-family: $titleFont;
    color: $black;
  }
  .fui-legend{
    display: none;
  }
}
