
.form-panel{

	@include touch {
		scroll-margin-top: 100px;
	}
	@include desktop {
		scroll-margin-top: 140px;
	}

  max-width: 1700px;
  margin: 5em auto;
  color: $white;
	position: relative;
	background-color: $blue-dark;
	@include gradient(180deg, $grad1);
	@include box_shadow(5,'bottom');
	@include panelHover;

	a{
		color: $white;
	}

  .content{
    @include touch {
      padding: 2em 0;
    }
    @include desktop {
      padding: 4em 3em;
    }
  }

  .title{
    color: $white;
  }

	input,textarea{
		border-radius: 0;
		font-family: $bodyFont;
	}

	button{
		font-family: $titleFont;
		background: $red;
		border: none;
		border-radius: 0;
		font-size: 1.6em;

		&:hover{
			background: $red-dark;
		}
	}
}
