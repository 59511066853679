
.hero-panel{
	position: relative;
	z-index: 10;


  @include mobile {
    padding: 3em 0;
    .logo{order:2}
    .text{order:1}
  }
	@include tablet {
    padding: 4em 0;
    .logo{order:2}
    .text{order:1}
  }
  @include desktop {
    padding: 8em 2em 5em 2em;
    .logo{order:1}
    .text{order:2}
  }

  background-color: $blue-dark;
  color: $white;
  background-size: cover;


	b{
		text-decoration: underline;
		color: $red;
	}

  .title{
    color: $white;
    font-weight: bold;
    text-transform: capitalize;
		padding: 0 0.5em;
  }

  .button{
    @include touch {
      width:90%;
    }
    @include desktop {
      width:49%;
    }

    &.is-large{
      font-size: 2em;
    }
  }

  .trust-signals{
    padding: 0 1.25em;
  }

}
