
.service-area-panel{
	@include panelHover;

	@include gradient(180deg, $grad1);
	position: relative;
  background-color: $blue-dark;
//  padding: 3em 2em 3.5em 2em;

  .title,ul{
    color: $white;
  }
  a{
    color: $white;
    &:hover{
      text-decoration: underline;
    }
  }


	// Top level areas
	.service-areas{
		padding: 1.5em 0;
		a{
			position: relative;
      &:before{
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        background-image: url('../img/icon-chevron.svg');
        background-size: contain;
        position: absolute;
        top: 7px;
        left: -30px;
			}
		}
	}


	// All suburb listing
	.locations-list{


		// Toggle menu
		summary{
			display: block;
			color: $white;
			padding: 0.5em;
			margin: 1em auto;
			width:200px;
			font-size: 1.25em;
			//border-top:1px solid darken($blue-light,20);;
		}

		.accordion-title{
			font-size: 0.7em;
			&:hover{
				text-decoration: underline;
			}
		}

		a{
			color: darken($blue-light,20);
			&:hover{
				color: $white;
			}
		}
	}


	.suburb-list{
		@include gradient(180deg, $grad1);
		padding: 2em;
		@include mobile {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      column-gap: 0;
    }
    @include tablet {
      columns: 4;
      -webkit-columns: 4;
      -moz-columns: 4;
      column-gap: 40px;
    }
    @include desktop {
      columns: 6;
      -webkit-columns: 6;
      -moz-columns: 6;
      column-gap: 80px;
    }
	}

}

.service-area-map{

	@include touch {
    height: 650px;
  }
  @include desktop {
    height: auto;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
