
.faq-block{

	.container{
		//padding: 2em;
		background-color: $blue-light;
	}

	.accordion-item{
		border-top: 1px solid lighten($blue-light,30);
		padding: 1em 2em 0.25em 2em;
	}
}
