
details{

	summary{
		cursor: pointer;
		&:hover{
			color: $red;
		}
	}

	a:hover{
		color: $black;
	}

}



.accordion{
	summary {
		position: relative;
	  list-style: none;
		margin-bottom: 1em;
	}
	summary::marker {
	  content: '';
	}


	.accordion-title{
		display: block;

		&:before{
			position: absolute;
			content: '+';
			width: 50px;
			height:50px;
			font-size: 1.85em;
			color: rgb(255, 255, 255);
			background-color: $red;;
			z-index: 5;
			top: 50%;
			left: 20px;
			transform: translateY(-50%);
		}

		.title{
			padding-left: 4em;
		}
	}

}
