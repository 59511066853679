.awards-block{

//  margin: 4em auto;

  .award{
    @include box_shadow(2,'bottom');
    @include touch {
      padding: 2em;
      margin: 0 $global-gutter;
    }
    @include desktop {
      padding: 4em;
    }

    .award-details{
      position: relative;
      padding-left: 3em;
      &:before{
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 10px;
        height:100%;
        background-image: linear-gradient(180deg, $red,$orange,$yellow);
      }
    }
  }

}



//
// .awards-block{
//   border-top: 3px solid $grey-xlight;
//   border-bottom: 3px solid $grey-xlight;
//   //border: 5px solid $grey-xlight;
//   //background-color: $grey-xlight;
//   //@include gradient(180deg, $grad3);
//   //@include box_shadow(5,'bottom');
//
//   @include touch {
//     padding: 1.5em $global-gutter 3em $global-gutter;
//     margin: 0 $global-gutter;
//     margin-bottom: 3em;
//   }
//   @include desktop {
//     padding: 2em $global-gutter 6em $global-gutter;
//     margin-bottom: 3em;
//   }
//
//   img{
//     margin: 0 auto;
//     @include touch {
//       max-width: 220px;
//     }
//     @include desktop {
//       max-width: 240px;
//     }
//   }
//   .logo{
//     text-align: center;
//     padding: 2em 0;
//   }
//
// }
