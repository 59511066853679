
.heading-block{
  color: $white;

  .title{
    color: $white;
    margin: 0;
  }

  .content{
    background-color: $blue-dark;
    @include gradient(180deg, $grad1);


    @include mobile {
      padding: 2em;
    }
    @include tablet {
      padding: 4em;
    }
  }

  ul{
    position: relative;
    text-align: left;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    // @include touch {
    //   left: 0em;
    // }
    // @include desktop {
    //   left: 3em;
    // }

    li{
      //background-color: red;
      // display: inline-block;
      // padding: 0.15em;
      //
      // @include touch {
      //   width: 30%;
      // }
      // @include desktop {
      //   width: 32%;
      // }
    }
  }
}
