$imageShadowSize: 30px;
$imageShadowSizeHover:25px;

/* Text and image component */
.text-image-block{
  img{
    @include animate;
    transform: translate(0%,0%);
    box-shadow: 0-$imageShadowSize $imageShadowSize 0px -3px rgba(219,219,219,1);
    -webkit-box-shadow: 0-$imageShadowSize $imageShadowSize 0px -3px rgba(219,219,219,1);
    -moz-box-shadow: 0-$imageShadowSize $imageShadowSize 0px -3px rgba(219,219,219,1);
    border: 6px solid $white;
  }
  a{
    img{
      &:hover{
        @include animate;
        transform: translate(2%,-2%);
        box-shadow: 0-$imageShadowSize $imageShadowSize 0px -3px rgba(204,55,47,0.5);
        -webkit-box-shadow: 0-$imageShadowSizeHover $imageShadowSizeHover 0px -3px rgba(204,55,47,1);
        -moz-box-shadow: 0-$imageShadowSizeHover $imageShadowSizeHover 0px -3px rgba(204,55,47,1);
      }
    }
  }

  &.image-left{
    .column:first-child{
      order: 0;
    }
  }
  &.image-right{
    .column:first-child{
      @include touch {
        order: 0;
      }
      @include desktop {
        order: 1;
      }
    }
  }
}
