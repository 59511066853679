
/* Image component */
.image-block{
  padding: 0 !important;
  text-align: center;
  margin: 0 auto;

  figure{
    display: block;
    line-height: 0;
  }

  &.small{
    max-width: $tablet;
  }
  &.medium{
    max-width: $desktop;
  }
  &.large{
    max-width: $fullhd;
  }
  &.fullwidth{
    max-width: 100%;
  }

  &.small,
  &.medium,
  &.large{
    img{
      border-radius: 2em;
    }
  }


}
