
// Mobile menu
.mobile-menu{
  position: fixed;
  z-index: 998;

  .nav-mobile{
    @include animate;
    transform: translateX(-100%);
    @include gradient(0deg,$grad1);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    ul{
      @include center;
      position: relative;
      width: 100%;
      height: auto;
      text-align: center;

      a{
        color: $white;
        text-decoration: none;

        @include mobile {
          font-size: 1.5em;
          margin: 0.25em 0;
        }
        @include tablet {
          font-size: 2em;
          margin: 0.5em 0;
        }

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  // Modifiers
  &.is-active{
    .nav-mobile{
      @include animate;
      transform: translateX(0%);
    }
  }
}
