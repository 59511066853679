
.button-block{
  a{
    //@include animate;

    font-weight: bold;
		font-family: $titleFont;
    color: $white;
    background: $red;
    text-decoration: none !important;


		@include mobile {
			padding: 1.1em 2em;
			font-size: 1em;
		}
		@include tablet {
			padding: 0.9em 2em;
			font-size: 1.5em;

		}

    &:hover{
      //@include animate;
			color: $white;
	    background: $red-dark;
			text-decoration: underline !important;;
    }
  }
}
