$topBarPadding:20px;
$iconHeight: 45px;

.topbar{
  @include animate();
  @include box_shadow(3,'bottom');
  background-color: $white;
  position: fixed;
  z-index: 999;
  top: 0;
  left:0;
  width:100%;
  height: auto;



  .logo{
    position: relative;
    top: 0.15em;
    @include touch {
      padding: 1em 0.85em;
    }
    @include desktop {
      padding: 1em 0.85em;
    }

    @include widescreen {
      padding: 1.25em 1em;
    }

    img{
      @include mobile {
        width: 160px;
      }
      @include tablet {
        width: 200px;
      }
      @include desktop {
        width: 250px;
      }
      @include widescreen {
        width: 280px;
      }
    }
  }



  .hamburger{
    transform: scale(1.25) translateX(-0%);
    transform-origin: center right;
    @include from(1350px) {
      display: none;
    }

    > .button{
      .icon{
        font-size: 1.4em;
      }
    }
  }



  .nav{
    display: inline-block;
    text-align: center;

    nav{
      @include until(1350px) {
        display: none;
      }
    }

    ul{
      text-align: center;
      display: inline-block;
    }

    li{
      display: inline-block;
      a{
        font-size: 1.3rem;
        font-weight: bold;
        text-decoration: none;
        color: $blue-dark;

        &:hover{
          background-color: $blue-dark;
          color: $white;
        }
        &.active{
          font-weight: bold;
        }
        &:focus{
          color: $red;
        }
      }
    }
  }

}








/* Modifiers */
/* =========================================== */
.topbar{
  &.is-active{

  }
}
