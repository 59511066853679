

.trust-block{
	.buttons{
		//border-top: 2px solid $grey-light;
		margin-top: 2em;
	}
	.button{
		color: $white;
		background-color: $red;
		padding: 0.5em 2em;
		margin:2px;

		@include touch {
	    width: 100%;
			font-size: 1.1em;
	  }
		@include desktop {
	    width: auto;
			font-size: 1.75em;
	  }

		.icon{
			display: inline-block;
			padding-right: 2em;
		}

		&:hover{
			background-color: $red-dark;
		}
	}
}
