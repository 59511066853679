
/* Text component */
.text-block{
  blockquote{
    font-size: 1.25em;
    margin: 2.5em 0 !important;
  }
  .content{
    img{
      display: block !important;
      width:auto;
    }
  }
  &.has-padding{
    padding: 6em 0;
  }
}
