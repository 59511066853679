
.collapsable-block{

	.accordion-trigger{
		text-align: left;
	}

	.accordion-item{
		@include animate;
		@include box_shadow(5,'bottom');
		background-color: $white;
		padding: 1.5em;

		&:hover{
			@include animate;
			@include box_shadow(3,'bottom');
		}
	}

	.accordion-content{
		padding: 0.5em 1.5em;
	}
}
