
.product-cta{
	background-color: $grey-light;
	@include gradient(180deg, $grad4);


	// .title{
	// 	padding: 0.4em 0.2em;
	// 	border: 6px solid $red;
	// }

	.buttons{
		//border-top: 2px solid $grey-light;
		margin-top: 2em;

	}
	.button{
		color: $white;
		background-color: $red;
		padding: 0.5em 2em;
		margin:2px;
		text-decoration: none !important;;

		@include touch {
			width: 100%;
			font-size: 1.1em;
		}
		@include desktop {
			width: auto;
			font-size: 1.3em;
		}

		.icon{
			display: inline-block;
			padding-right: 2em;
		}

		&:hover{
			background-color: $red-dark;
		}
	}

}
