
//
// .brand-block-title{
// 	background-color: $blue;
// 	padding: 1.25em;
// 	text-align: center;
// 	color: $white;
// 	font-size: 1.5em;
// 	line-height: 1em;
// }

.brand-block{
		background-color: $grey-light;
	 //	margin: 2em auto;
		padding: 2em;

		img{
		//	max-width: 250px;
		}

		.title{
			font-weight: bold;
			color: $black;
			//background-color: darken($grey-light,10);
			//@include gradient(90deg, $grad1);
			border-bottom: 1px solid $black;
			padding: 0.5em;
			display: inline-block;

			@include touch {
				padding: 1em 0;
			}
			@include desktop {
				padding: 1em 0;
			}

		}
}
