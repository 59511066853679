
.product-carousel {
	//background-color: green;

	.carousel-cell {
		width: 100%;
	}
	ol{
		margin: 0;
	}
}
